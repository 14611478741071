import styled from "styled-components"
import { device } from "../styles/media"
export const AboutSection = styled.section`
  padding-bottom: 30px;
  font-size: 18px;
  p {
    margin: 10px 0;
  }
  strong {
    font-weight: bold;
  }
  h4 {
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
  }
  ul {
    margin: 10px 0;

    li {
      padding-left: 20px;
      display: flex;
      position: relative;

      &:before {
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #334061;
      }
      @media ${device.mobileL} {
        border-bottom: solid 1px #d2d2d2;
        padding-bottom: 10px;
        &:before {
          top: -10px;
        }
      }
    }
  }
  .mainTitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    padding: 20px 10px 100px;
  }
`
